.container {
    background-color: #D5E7B8;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.add-button-container {
    padding: 20px;
}

.title-container {
    padding: 10px;
    font-size: 20px;
}