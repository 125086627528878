.Liststab-container {
    padding: 10px;
    text-align: center;
}

.Liststab-table-container {
    width: 90%;
    margin: 0 auto;
}

.Liststab-create-items-section {
    padding-top: 20px;
    width: 90%;
    margin: 0 auto;
}

.Liststab-title {
    font-size: 20pt;
    font-weight: bold;
    color: #1A3B0E;
    margin-bottom: 20px;
}