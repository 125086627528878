.Loginpage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  font-family: "Avenir";
}
  
.Loginpage-title {
  background-color: #D5E7B8;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  text-align: center;
}

.Loginpage-image {
  margin: 20px;
  max-width: 400px;
  max-height: 400px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  border-radius: 10px;
}

.Loginpage-logo {
  width: 75%;
  height: 75%;
}