.Poststab-container {
    padding: 10px;
    text-align: center;
    margin: auto;
    color: #102408;
}

.Poststab-title {
    font-size: 20pt;
    font-weight: bold;
    color: #1A3B0E;
    margin-bottom: 20px;
}

.Poststab-post-container {
    display:inline-block;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #D5E7B8;
    border-radius: 3px;
    text-align: center;
    width: 75%;
}

.Poststab-post-text {
    font-size: 24px;
    margin-bottom: 10px;
}

.Poststab-post-delete-icon {
    width: 100%;
    text-align: right;
}