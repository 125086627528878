.Tile-container:hover {
    background-color: white;
}

.Tile-table {
     border-radius: 20px;
     padding: 5px;
     width: 100%;
     height: 100%;
}

.Tile-name {
    font-weight: 500;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Tile-address {
    color: #6A6A6A;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Tile-date {
    color: #6A6A6A;
    font-weight: 400;
    padding-top: 5px;
}
