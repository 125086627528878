.Tabs-layout {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #FFFBF0;
    border-bottom: 2px solid #1A3B0E;
    z-index: 100000;
}

.Tab-container {
    padding-top: 50px;
}