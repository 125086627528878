.Homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.Homepage-title {
  color: #103803;
  font-family: "Avenir";
  background-color: #D5E7B8;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
}

.Homepage-onboard-section {
  color: #103803;
  font-family: "Avenir";
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.Homepage-abount-section-container {
  width: 100%;
}