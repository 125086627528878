.Invite-container {

    cursor: pointer;

    font-weight: bold;

    width: 100%;
    height: 100%;
    min-width: 150px;
    padding: 10;
    
    text-align: center;

    border-radius: 7;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 4px #1A3B0E;
    background-color: #D5E7B8;

}