.About-mission-statement {
    background-color: #D5E7B8;
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
}

.Mission-statement-text-section {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 360px;
    max-width: 473px;
    display: inline-block;
    text-align: center;
}

.Mission-statement-title {
    float: left;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    color: #103803;
}

.Mission-statement-content {
    float: right;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    color: #103803;
}

.Mission-statement-image {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 400px;
    max-height: 400px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.Homepage-logo {
    width: 75%;
    height: 75%;
}

.Subsection {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 20px;
}

.Subsection-text-section {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 360px;
    max-width: 473px;
    display: inline-block;
    text-align: center;
}

.Subsection-text-title-right-adjusted {
    float: right;
    font-weight: bold;
    font-size: 36px;
    text-align: right;
    color: #103803;
}

.Subsection-text-title-left-adjusted {
    float: left;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    color: #103803;
}

.Subsection-text-subtext-right-adjusted {
    float: right;
    font-size: 24px;
    line-height: 36px;
    text-align: right;
    color: #103803;
}

.Subsection-text-subtext-left-adjusted {
    float: left;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    color: #103803;
}

.Subsection-image {
    margin: 20px;
    max-width: 400px;
    max-height: 400px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    border-radius: 10px;
}

.Subsection-logo {
    width: 75%;
    height: 75%;
    border-radius: 15px;
}

.Appstore-section {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}