.Faqpage-container {
    text-align: center;
}

.Faqpage-title {
    font-size: 40px;
    font-weight: bold;
    padding: 20px;
    color: #1A3B0E;
}

.Faqpage-no-invites-faq {
    width: 80%;
    display:inline-block;
}

.Faqpage-accordion-summary-container {
    width: 100%;
}