.Carstab-container {
    padding: 10px;
    text-align: center;
}

.Carstab-accordion-container {
    padding: 5px;
}

.Carstab-accordion-summary-container {
    width: 100%;
}

.Carstab-title {
    font-size: 20pt;
    font-weight: bold;
    color: #1A3B0E;
    margin-bottom: 20px;
}

.Carstab-add-car-accordion {
    margin-bottom: 20px;
}

.Carstab-passenger-request-tile {
    width: 25%;
    cursor: pointer;
    text-align: center;
    border-radius: 7;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 4px #1A3B0E;
    margin-bottom: 30px;
    min-width: 150px;
}

.Carstab-passenger-requests-row {
    display: flex;
    justify-content: space-between;
}

.Carstab-passenger-request-row-buttons {
    display: flex;
}