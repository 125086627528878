.App {
  display: flex;
  flex-direction: column;
}

.App-header {
  position: sticky;
  top: 0;
  z-index: 1000000000;
}

.App-body {
  min-height: calc(100vh - 70px);
}

.App-footer {
  min-height: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #D5E7B8;
}