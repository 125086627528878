.NewCottage-button {
    width: 100%;
    height: 125px;
    cursor: pointer;
    object-fit: fill;
    background-image: url('../../Assets/PaintStroke.png'), url('../../Assets/NewCottage.jpg');
    background-repeat: no-repeat, no-repeat;
    background-position: center, center;
    background-size: cover, cover;
    /* background-size: 400px, 100%; */
    border-radius: 20px;
    box-shadow: 0px 0px 10px 4px #1A3B0E;
    font-size: 20pt;
    font-family: monospace;
    font-weight: bold;
    color: black;
}

.NewCottage-container {
    height: 100%;
    display: flex;
    flex-flow: column;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
}

.Invites-inbox-loading-overlay {
    height: 100%;
    width: 100%;
}

.Invites-accordion-container {
    padding: 5px;
}

.Invite-inbox-cell-summary-container {
    width: 100%;
    text-align: center;
}

.Invite-inbox-cell-text-label {
    padding: 2px;
    margin: 0;
}

.Invite-buttons-container {
    display: flex;
    justify-content:space-evenly;
    align-items: stretch;
}

.Invite-button-container {
    margin-right: 5px;
    margin-left: 5px;
    flex: 1;
}