.Emptytab-image-container {
    padding-top: 20px;
    width: 100%;
}

 .Emptytab-image {
    background-image: url("/src/Assets/EmptySign.png");
    width: 20%;
    min-width: 200px;
    height: auto;
 }

 .Emptytab-no-invites-label {
    font-size: 16px;
    display: inline-block;
    background-color: #ECEBC9;
    padding: 10px;
    border-radius: 7px;
}