.Attendeestab-container {
    padding: 10px;
    text-align: center;
    margin: auto;
}

.Attendeestab-send-invite-container {
    padding-bottom: 20px;
}

.Attendeestab-title {
    font-size: 20pt;
    font-weight: bold;
    color: #1A3B0E;
    margin-bottom: 20px;
}

.Attendee-list-cell-container {
    display:inline-block;
    background-color: #D5E7B8;
    margin: 10px;
    padding: 10px;
    border-radius: 7px;
    width: 75%
}

.Attendeestab-invites-title {
    font-size: 20pt;
    font-weight: bold;
    color: #1A3B0E;
    margin-bottom: 20px;
    margin-top: 40px;
}

.Attendeestab-no-invites-label {
    font-size: 16px;
    display: inline-block;
    background-color: #ECEBC9;
    padding: 10px;
    border-radius: 7px;
}