.Contactus-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
}

.Contactus-title {
    color: #103803;
    background-color: #D5E7B8;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}

.Contactus-text-container {
    margin: 10px;
    text-align: center;
}