.Socialspage-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Socialspage-title {
    font-size: 50px;
    font-weight: bold;
    padding: 10px;
    margin: 20px;
    color: #1A3B0E;
    background-color: #D5E7B8;
    border-radius: 10px;
}