.Cottageinfotab-container {
    padding: 10px;
    text-align: center;
}

.Cottageinfotab-text {
    background-color: #B1D291;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #1A3B0E;
    width: 250px;  
    margin: 0 auto;
}

.Cottageinfotab-label {
    padding-top: 20px;
}