.Profile-container {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    width: 100vw;
}

.Profile-form-title {
    font-size: 20pt;
    font-family: monospace;
    font-weight: bold;
    color: #1A3B0E;
}

