.Footer-section {
    font-weight: bold;
}

.Footer-item:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Footer-bar {
    border-bottom: 1px solid #888888;
    margin-top: 10px;
    margin-bottom: 10px;
}
