.Createcottage-form-container {
    padding: 5px;
    padding-bottom: 20px;
    text-align: center;
}

.Closebutton-container {
    text-align: right;
    padding: 5px;
}

.Createcottage-form-title {
    font-size: 24pt;
    font-weight: bold;
    color: #1A3B0E;
}

.Createcottage-form-date-container {
    padding-right: 5px;
    padding-left: 5px;
}

.Createcottage-form-toggle-label {
    color: #1A3B0E;
}

.Createcottage-form-date-label {
    color: #1A3B0E;
    padding-bottom: 5px;
}